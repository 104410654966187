import React, { useState } from 'react';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';

import styles from '../SiteMenu.module.scss';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation, useNavigate } from 'react-router-dom';
import ChevronButton from 'src/shared/components/chevron-button/ChevronButton';
import { openSiteMenu } from 'src/global/state/site-menu/siteMenuSlice';
import { insightRouteSettings } from 'src/app/insights/Insights';
import useProject from 'src/shared/hooks/useProject';
import { SubscriptionType } from 'src/shared/types';

const InsightsMenu = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { currentProjectFilters, currentProjectSelectedHabitatAssayType, currentProjectSummary } = useProject();

    const habitatAssays = currentProjectFilters?.habitatAssays;
    const isSingleTimepoint = !!habitatAssays?.find(entry => entry.key === currentProjectSelectedHabitatAssayType?.key)?.singleTimepoint;
    const isHabitatInsightsProject = currentProjectSummary?.survey?.subscription?.name === SubscriptionType.SURVEY_WITH_HABITAT_INSIGHTS;

    if (!appRoutesAvailability.insights) {
        return null;
    }

    const getMenuItemClassNames = () => {
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: pathname.startsWith('/insights'),
        });
    };

    const getMenuChildItemClassNames = (path: string) => {
        return classNames(styles.menuItem, styles.menuItemChild, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: pathname.includes(path),
        });
    };

    const toggleExpanded = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        setIsExpanded(!isExpanded);
    };
    const openMenu = () => {
        dispatch(openSiteMenu());
        setIsExpanded(!isExpanded);
    };

    const onMenuClick = (path: string) => {
        setIsExpanded(false);
        navigate('/insights' + path);
    };

    return (
        <>
            <div className={getMenuItemClassNames()} onClick={openMenu}>
                <div className={styles.icon}>
                    <InsightsOutlinedIcon />
                </div>
                <div className={styles.label}>EcoStats</div>
                {isOpen && (
                    <ChevronButton
                        className={styles.chevron}
                        animate={true}
                        direction={isExpanded ? 'down' : 'right'}
                        onClick={e => toggleExpanded(e)}
                    />
                )}
            </div>
            {isOpen && isExpanded && (
                <>
                    {isHabitatInsightsProject && (
                        <div
                            className={getMenuChildItemClassNames(insightRouteSettings.habitatInsightsEcologicalTrends.path)}
                            onClick={() => onMenuClick(insightRouteSettings.habitatInsightsEcologicalTrends.path)}
                        >
                            <div className={styles.label}>{insightRouteSettings.habitatInsightsEcologicalTrends.name}</div>
                        </div>
                    )}

                    {!isHabitatInsightsProject && (
                        <>
                            {!isSingleTimepoint && (
                                <div
                                    className={getMenuChildItemClassNames(insightRouteSettings.ecologicalTrends.path)}
                                    onClick={() => onMenuClick(insightRouteSettings.ecologicalTrends.path)}
                                >
                                    <div className={styles.label}>{insightRouteSettings.ecologicalTrends.name}</div>
                                </div>
                            )}
                            <div
                                className={getMenuChildItemClassNames(insightRouteSettings.sampleComparison.path)}
                                onClick={() => onMenuClick(insightRouteSettings.sampleComparison.path)}
                            >
                                <div className={styles.label}>{insightRouteSettings.sampleComparison.name}</div>
                            </div>
                            <div
                                className={getMenuChildItemClassNames(insightRouteSettings.statisticalComparison.path)}
                                onClick={() => onMenuClick(insightRouteSettings.statisticalComparison.path)}
                            >
                                <div className={styles.label}>{insightRouteSettings.statisticalComparison.name}</div>
                            </div>
                            <div
                                className={getMenuChildItemClassNames(insightRouteSettings.communityComparison.path)}
                                onClick={() => onMenuClick(insightRouteSettings.communityComparison.path)}
                            >
                                <div className={styles.label}>{insightRouteSettings.communityComparison.name}</div>
                            </div>
                            <div
                                className={getMenuChildItemClassNames(insightRouteSettings.taxonomicComposition.path)}
                                onClick={() => onMenuClick(insightRouteSettings.taxonomicComposition.path)}
                            >
                                <div className={styles.label}>{insightRouteSettings.taxonomicComposition.name}</div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default InsightsMenu;
