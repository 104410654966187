import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useProject from 'src/shared/hooks/useProject';
import useMediaQuery from 'src/shared/hooks/useMediaQuery';
import cmsService from 'src/services/cms-service';
import { CMSSpecies } from 'src/shared/types';
import IucnStatusChips from 'src/app/maps/views/samples/components/samples-sidebar/components/iucn-status-chips/IucnStatusChips';
import useIsViewed from 'src/shared/hooks/useIsViewed';
import useDashboardAnalytics from 'src/app/dashboard/hooks/useDashboardAnalytics';
import styles from './SpeciesHighlight.module.scss';
import useRefWithRerender from 'src/shared/hooks/useRefWithRerender';
import { useAppDispatch } from 'src/store';
import { updateFilters } from 'src/app/maps/views/samples/state/samplesSlice';
import { SecondaryButton } from 'src/shared/components/button/Button';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { getIucnCodeFromLabel, getConservationStatusTag } from 'src/shared/helpers/iucnStatusHelper';

interface SpeciesHighlightProps {
    isFishMetricsPresent: boolean;
    isSingleTimepoint?: boolean;
}

const SpeciesHighlight = (props: SpeciesHighlightProps) => {
    const { isFishMetricsPresent, isSingleTimepoint } = props;
    const [ref, callbackRef] = useRefWithRerender<HTMLDivElement>(null);
    const isViewed = useIsViewed(ref);
    const { trackSpeciesHighlightViewed } = useDashboardAnalytics();
    const { currentProjectId, currentProjectSelectedHabitatAssayType: habitatAssayType } = useProject();
    const [speciesDetails, setSpeciesDetails] = useState<CMSSpecies | null>(null);
    const isMediumScreen = useMediaQuery('(min-width: 920px) and (max-width: 1199px) ');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!currentProjectId || !habitatAssayType) {
            return;
        }
        const fetchSpeciesDetails = async () => {
            const habitat = habitatAssayType.habitat.habitatKey;
            const assay = habitatAssayType.assay.assayId;

            const details = await cmsService.getHighlightedSpecies({
                projectId: currentProjectId,
                habitat,
                assay,
            });

            setSpeciesDetails(details);
        };

        fetchSpeciesDetails();

        return () => {
            setSpeciesDetails(null);
        };
    }, [currentProjectId, habitatAssayType]);

    useEffect(() => {
        if (isViewed) {
            trackSpeciesHighlightViewed();
        }
    }, [isViewed]);

    const header = (
        <div className={styles.titleContainer}>
            <div className={styles.title}>
                <span>Species highlight</span>
            </div>
            <div className={styles.subTitle}>
                <span> Recently discovered species of interest from the latest sampling</span>
            </div>
        </div>
    );

    if (!speciesDetails) {
        return null;
    }

    const iucnStatus = getConservationStatusTag({ iucnRedListStatus: getIucnCodeFromLabel(speciesDetails.iucn_red_list_status) });

    const subHeader = (
        <>
            <div className={styles.scientificName}>
                <span>{speciesDetails.scientific_name}</span>
            </div>
            <div className={styles.commonName} ref={callbackRef}>
                <span>&nbsp;{speciesDetails.common_name}</span>
            </div>
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <IucnStatusChips
                    isInvasive={false}
                    iucnStatusLabel={speciesDetails.iucn_red_list_status}
                    iucnStatusType={iucnStatus.iucnStatusType}
                />
            </div>
        </>
    );

    const handleClick = () => {
        dispatch(
            updateFilters({
                speciesHighlight: speciesDetails.scientific_name,
            })
        );
        navigate('/map/samples');
    };

    const content = (
        <div className={styles.descriptionContainer}>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: speciesDetails.description }}></div>
            <div className={styles.showOnMapButton}>
                <SecondaryButton onClick={handleClick}>
                    Show on map &nbsp;
                    <MapOutlinedIcon />
                </SecondaryButton>
            </div>
        </div>
    );

    const speciesTitles = speciesDetails.common_name
        ? `${speciesDetails.common_name}, ${speciesDetails.scientific_name}`
        : `${speciesDetails.scientific_name}`;
    const speciesImage = <img src={speciesDetails.image.large} title={speciesTitles} />;
    const shouldUseHorizontalLayout = !isFishMetricsPresent || isSingleTimepoint;

    return (
        <div className={styles.speciesHighlightContainer}>
            <div className={shouldUseHorizontalLayout ? styles.rowContainer : styles.columnContainer}>
                <div className={styles.imageContainer}>{speciesImage}</div>
                <div className={styles.contentContainer}>
                    {header}
                    {subHeader}
                    {!isMediumScreen && content}
                </div>
            </div>
            {isMediumScreen && content}
        </div>
    );
};

export default SpeciesHighlight;
