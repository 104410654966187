import React, { useState, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getEditProjectUserFormState, getEditProjectUserFormStep } from '../../state/userManagementSelector';
import {
    EditProjectUserFormState,
    setEditUserFormState,
    setEditUserView,
    setEditUserFormMode,
    updateGeneralUiState,
    reset,
} from '../../state/userManagementSlice';
import UserDetails from '../user-details/UserDetails';
import AssignProjects from '../assign-projects/AssignProjects';
import { SecondaryButton, PrimaryButton } from 'src/shared/components/button/Button';
import Input from 'src/shared/components/input/Input';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import SaveIcon from '@mui/icons-material/Save';
import {
    useUpdateProjectUserMutation,
    useEnableUserMutation,
    useDisableUserMutation,
    useAdminUpdateUserMutation,
} from '../../state/api/userManagementGraphSlice';
import ErrorMessage from 'src/shared/components/error-msg/ErrorMessage';
import { Access, Role } from 'src/shared/types';
import Checkbox from 'src/shared/components/checkbox/Checkbox';
import styles from './EditProjectUser.module.scss';
import { getUserStatusFromEnum } from '../user-management-list/UserManagementList';
import StatusIndicator from 'src/shared/components/status-indicator/StatusIndicator';
import useAuthService from 'src/shared/hooks/useAuthService';
import useProject from 'src/shared/hooks/useProject';
import { isCountryCodeValid as checkIsCountryCodeValid } from 'src/shared/helpers/phoneNumberHelpers';
import AssignAdminRoles from 'src/app/admin/views/user-management/components/assign-admin-roles/AssignAdminRoles';
import EditProjectUserHeader from './components/EditProjectUserHeader';
import useCustomer from 'src/app/customers/hooks/useCustomer';
import usePermissions from 'src/shared/hooks/usePermissions';
import UnassignUserFromOrganisation from './components/UnassignUserFromOrganisation';
import ReassignUserToOrganisation from './components/ReassignUserToOrganisation';
import InfoMessage from 'src/shared/components/info-message/InfoMessage';
import WarningMessage from 'src/shared/components/warning-message/WarningMessage';
import { formatPhoneNumberWithCountryCode } from 'src/app/admin/views/user-management/components/userManagementHelpers';

export const EditProjectUser = () => {
    const dispatch = useAppDispatch();
    const { currentCustomerProjects } = useProject();
    const { isCurrentUserSuperAdmin, isCurrentUserOnboardingManager } = useAuthService();
    const { currentCustomerId: customerId } = useCustomer();
    const [errorMessage, setErrorMessage] = useState('');
    const { hasCustomerLevelRole } = usePermissions();
    const [isCountryCodeValid, setIsCountryCodeValid] = useState<boolean>(true);
    const formState = useAppSelector(getEditProjectUserFormState);
    const editUserView = useAppSelector(getEditProjectUserFormStep);
    const [isSavebtnDisabled, setSaveBtnDisabled] = useState<boolean>(true);
    const [updateProjectUser] = useUpdateProjectUserMutation();
    const [reActivateUser] = useEnableUserMutation();
    const [deActivateUser] = useDisableUserMutation();
    const [adminUpdateUser] = useAdminUpdateUserMutation();
    const isActiveOrInvited = formState.status === 'Active' || formState.status === 'Invited';

    const isAssignedToCustomer = formState.customers.some(customer => customer.customerId === customerId && customer.active);
    const editMessage = 'User successfully edited';
    const userDetails = (
        <div className={styles.header}>
            <UserDetails {...formState} />
        </div>
    );
    const userRoles = formState?.customers.find(customer => customer.customerId === customerId)?.roles;
    const existingProjectsRef = useRef<{ projectId: string; roles: string[] | null }[]>();

    useEffect(() => {
        if (!existingProjectsRef.current) {
            existingProjectsRef.current = formState.projects;
        }
    }, [formState.projects]);

    const userStatusIndicator = <StatusIndicator status={getUserStatusFromEnum(formState.status)} statusText={formState.status} />;

    const notifyUser = (
        <Checkbox
            label={'Send user an email to notify them of this change'}
            checked={formState.notify}
            onChange={value => onFormChange({ notify: value })}
            className={styles.notifyUser}
        />
    );

    useEffect(() => {
        const isPhoneValid = !isNaN(Number(formState.phone)) && isFinite(Number(formState.phone));
        const compareAttributes = ['firstName', 'lastName'];
        const phoneNumberMatch = isEqual(`${formState.phoneCountryCode}${formState.phone}`, formState.phone);
        const attributesMatch = isEqual(pick(formState, compareAttributes), pick(formState, compareAttributes));
        const isDisabled = Boolean(!isPhoneValid || (attributesMatch && phoneNumberMatch));
        setSaveBtnDisabled(isDisabled);
    }, [formState.phone, formState.firstName, formState.lastName]);

    useEffect(() => {
        const { projects } = formState;
        const currentCustomerProjectIds = currentCustomerProjects?.map(project => project.projectId) || [];

        const filteredProjects = projects.filter(project => currentCustomerProjectIds.includes(project.projectId));

        const disableSubmitArray = filteredProjects.map(entry => {
            const roles = existingProjectsRef.current?.find(project => project.projectId === entry.projectId)?.roles;
            return !entry.roles?.length || (roles && entry.roles?.length === roles.length) || false;
        });
        disableSubmitArray.push(projects.length === (existingProjectsRef.current?.length || 0));

        setSaveBtnDisabled(
            disableSubmitArray.every(Boolean) || filteredProjects.some(project => project.projectId === '' || !project.roles?.length)
        );
    }, [formState.projects]);

    useEffect(() => {
        const hasRoles = formState.roles.length > 0;
        setSaveBtnDisabled(!isSavebtnDisabled && !hasRoles);
    }, [formState.roles]);

    useEffect(() => {
        setSaveBtnDisabled(true);
    }, []);

    useEffect(() => {
        const updatedFormState = { ...formState, notify: !isSavebtnDisabled };
        dispatch(
            setEditUserFormState({
                ...updatedFormState,
                roles: userRoles as Role[],
            })
        );
    }, [isSavebtnDisabled]);

    const activeStatusText = isActiveOrInvited ? 'Deactivate' : 'Reactivate';
    const activeStatusIcon = isActiveOrInvited ? <PersonOffIcon /> : <PersonIcon />;
    const assignStatusText = isAssignedToCustomer ? 'Unassign' : 'Assign';
    const assignStatusIcon = isAssignedToCustomer ? <PersonOffIcon /> : <PersonIcon />;

    /* eslint-disable @typescript-eslint/no-inferrable-types */
    const resetForm = (message: string, shouldShowSuccessAlert: boolean = true) => {
        dispatch(reset());
        dispatch(
            updateGeneralUiState({
                shouldShowEditUserModal: false,
                shouldShowSuccessAlert: shouldShowSuccessAlert,
                successStatusMessage: message,
            })
        );
    };

    const updateUserStatus = async () => {
        if (isActiveOrInvited) {
            try {
                await deActivateUser({
                    email: formState.email,
                    notify: formState.notify,
                }).unwrap();
                resetForm(editMessage);
            } catch (error) {
                console.error('rejected', error);
                setErrorMessage('Something went wrong. Please try again later.');
            }
        } else {
            try {
                await reActivateUser({
                    email: formState.email,
                    notify: true,
                }).unwrap();

                batch(() => {
                    dispatch(
                        updateGeneralUiState({
                            shouldShowSuccessAlert: true,
                            successStatusMessage: editMessage,
                        })
                    );
                    dispatch(setEditUserView('default'));
                });
            } catch (error) {
                console.error('rejected', error);
                setErrorMessage('Something went wrong. Please try again later.');
            }
        }
    };

    const updateUserDetails = async () => {
        try {
            const phoneNumber = formatPhoneNumberWithCountryCode(formState.phoneCountryCode, formState.phone);
            await updateProjectUser({
                ...formState,
                tnc_consent: formState.tnc_consent ? true : false,
                customerId: customerId,
                phone: phoneNumber,
                notify: formState.notify,
            }).unwrap();

            if (isCurrentUserSuperAdmin || isCurrentUserOnboardingManager) {
                const { firstName, lastName, cognitoId, projects } = formState;
                const updatedUserParams = { firstName, lastName, cognitoId, projects };
                await adminUpdateUser({
                    ...updatedUserParams,
                    phone: phoneNumber,
                    customers: [
                        {
                            customerId: customerId,
                            roles: [...formState.roles],
                        },
                    ],
                    tnc_consent: formState.tnc_consent ? true : false,
                }).unwrap();
            }

            resetForm(editMessage);
        } catch (error) {
            console.error('rejected', error);
            setErrorMessage('Something went wrong. Please try again later.');
        }
    };

    const saveButton = (
        <PrimaryButton onClick={updateUserDetails} disabled={isSavebtnDisabled}>
            <SaveIcon />
            &nbsp;Save changes
        </PrimaryButton>
    );

    const backButton = (
        <SecondaryButton
            onClick={() => {
                setErrorMessage('');
                dispatch(setEditUserView('default'));
            }}
        >
            Back
        </SecondaryButton>
    );

    // Customer admin can assign or unassign users from an organization
    const isCurrentUserCustomerAdmin = hasCustomerLevelRole({
        customerId,
        role: Role.CUSTOMER_ADMIN,
    });
    const isAllowedToAssignOrUnassign = isCurrentUserCustomerAdmin;

    const organisationAssignButton = isAllowedToAssignOrUnassign ? (
        <SecondaryButton
            onClick={() => {
                batch(() => {
                    dispatch(setEditUserFormMode(Access.UPDATE));
                    dispatch(setEditUserView(isAssignedToCustomer ? 'unassign' : 'reassign'));
                });
            }}
        >
            {assignStatusIcon}&nbsp;{`${assignStatusText} ${isAssignedToCustomer ? 'from' : 'to'} this organisation`}
        </SecondaryButton>
    ) : null;

    const onFormChange = (fragment: Partial<EditProjectUserFormState['formState']>) => {
        dispatch(
            setEditUserFormState({
                ...fragment,
            })
        );
    };

    const handleBlur = () => {
        setIsCountryCodeValid(!formState?.phoneCountryCode || checkIsCountryCodeValid(formState?.phoneCountryCode));
    };

    //disable projects permissions if user is not active or invited and customer has no projects
    const disableProjectPermissions = !isActiveOrInvited || (currentCustomerProjects?.length ?? 0) < 1;
    // Alow only super admin to activate or deactivate users
    const isAllowedToActivateOrDeactivate = isCurrentUserSuperAdmin;

    return (
        <div className={styles.editProjectUser}>
            <div className={styles.title}>Edit user</div>

            {editUserView === 'default' && (
                <div className={styles.sectionContainer}>
                    <div className={styles.section}>
                        <EditProjectUserHeader setErrorMessage={setErrorMessage} />
                    </div>
                    {isAssignedToCustomer && (
                        <>
                            {(isCurrentUserSuperAdmin || isCurrentUserOnboardingManager) && (
                                <div className={styles.section}>
                                    <div className={styles.sectionHeading}>Admin roles</div>
                                    <AssignAdminRoles onChange={onFormChange} roles={formState.roles} defaultRoles={userRoles as Role[]} />
                                </div>
                            )}
                            <div className={styles.section}>
                                <div className={styles.sectionHeading}>Project permissions</div>
                                <AssignProjects
                                    formState={formState}
                                    onFormChange={onFormChange}
                                    formMode={isActiveOrInvited ? Access.UPDATE : Access.VIEW}
                                    disabled={disableProjectPermissions}
                                />
                            </div>

                            <span className={styles.sectionDivider}></span>
                            {/*
                                TODO:Showing button only for active or invited status for now.
                                Disabling Reactivate option until the user flow is agreed
                            */}
                            {isActiveOrInvited && (
                                <>
                                    <div>{notifyUser}</div>

                                    <div className={styles.bottomNavigation}>
                                        {saveButton}
                                        {isAllowedToActivateOrDeactivate && (
                                            <SecondaryButton
                                                onClick={() => {
                                                    dispatch(setEditUserView('activate-or-deactivate'));
                                                }}
                                            >
                                                {activeStatusIcon}&nbsp;
                                                {`${activeStatusText} Account`}
                                            </SecondaryButton>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <span className={styles.sectionDivider}></span>
                    <div className={styles.bottomNavigation}>{organisationAssignButton}</div>
                </div>
            )}
            {editUserView === 'activate-or-deactivate' && (
                <>
                    <div className={styles.mainText}>Are you sure you want to {activeStatusText.toLowerCase()} the following user?</div>

                    {userDetails}
                    <div className={styles.subHeader}>{userStatusIndicator}</div>
                    <span className={styles.sectionDivider}></span>
                    <WarningMessage
                        message={
                            <>
                                This user will {isActiveOrInvited ? 'lose' : 'regain'} access to the NatureMetrics platform and their
                                assigned projects <br />
                                <br />
                                You can {isActiveOrInvited ? 'reactivate' : 'deactivate'} this user at a later time
                            </>
                        }
                    />

                    {isActiveOrInvited && <div>{notifyUser}</div>}

                    <div className={`${styles.bottomNavigation} ${styles['bottomNavigationRow']}`}>
                        <div>{backButton}</div>
                        <div>
                            <PrimaryButton onClick={updateUserStatus}>
                                {activeStatusIcon}&nbsp;{activeStatusText}
                            </PrimaryButton>
                        </div>
                    </div>
                </>
            )}
            {editUserView === 'edit-user-details' && (
                <>
                    <div className={styles.section}>
                        <EditProjectUserHeader setErrorMessage={setErrorMessage} hideEditLink={true} />
                    </div>
                    <span className={styles.sectionDivider}></span>
                    <div className={styles.fields}>
                        <Input
                            value={formState.firstName}
                            placeholder='First name'
                            autoComplete='off'
                            onChange={event => onFormChange({ firstName: event.target.value })}
                        ></Input>
                        <Input
                            value={formState.lastName}
                            placeholder='Last name'
                            autoComplete='off'
                            onChange={event => onFormChange({ lastName: event.target.value })}
                        ></Input>
                        <div className={styles.phoneInput}>
                            <Input
                                value={formState.phoneCountryCode ?? ''}
                                placeholder='+44'
                                hasError={!isCountryCodeValid}
                                maxLength={4}
                                hideMaxLengthLabel={true}
                                onBlur={handleBlur}
                                onChange={event => onFormChange({ phoneCountryCode: event.target.value })}
                            ></Input>
                            <Input
                                value={formState.phone ? formState.phone.replace(formState.phoneCountryCode ?? '', '') : ''}
                                placeholder='Mobile number'
                                onChange={event => onFormChange({ phone: event.target.value })}
                                autoComplete='off'
                                maxLength={15}
                                hasError={Boolean(formState.phone) && !isFinite(Number(formState.phone))}
                                hideMaxLengthLabel={true}
                                onBlur={handleBlur}
                            ></Input>
                        </div>
                        <Input value={formState.email} placeholder='Email' disabled></Input>
                    </div>
                    <InfoMessage
                        message={
                            <>
                                User account email address cannot be changed. <br />
                                <br /> If this email address is no longer valid, please deactivate this user and create a new user account
                            </>
                        }
                    />
                    <div>{notifyUser}</div>

                    <div className={`${styles.bottomNavigation} ${styles['bottomNavigationRow']}`}>
                        {backButton}
                        {saveButton}
                    </div>
                </>
            )}
            {editUserView === 'unassign' && <UnassignUserFromOrganisation />}
            {editUserView === 'reassign' && <ReassignUserToOrganisation />}
            <div>{errorMessage && <ErrorMessage message={errorMessage} />}</div>
        </div>
    );
};
