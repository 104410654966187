import React from 'react';
import Chip from 'src/shared/components/chip/Chip';
import styles from './TestTypeChip.module.scss';
import useCMSTestTypes from 'src/shared/hooks/cms/useCMSTestTypes';

type TestTypeChipProps = {
    testTypeKey: string;
    fallbackContent?: React.ReactNode;
};

const TestTypeChip = ({ testTypeKey, fallbackContent }: TestTypeChipProps) => {
    const cmsTestTypes = useCMSTestTypes();

    const matchedTest = cmsTestTypes.find(cmsTestTypes => cmsTestTypes.habitatAssayKey === testTypeKey);

    if (!matchedTest && !fallbackContent) {
        return null;
    }

    if (!matchedTest) {
        return (
            <Chip
                backgroundColor='#F6F6F6'
                label={
                    <div className={styles.testTypeChip}>
                        <div>{fallbackContent}</div>
                    </div>
                }
            />
        );
    }

    const label = `${matchedTest.habitatName} ${matchedTest.assayName}`;
    return (
        <Chip
            backgroundColor='#F6F6F6'
            label={
                <div className={styles.testTypeChip}>
                    {matchedTest.image.thumbnail && <img src={matchedTest.image.thumbnail} alt={label} />}
                    <div>
                        <div>{matchedTest.habitatName}</div>
                        <div>{matchedTest.assayName}</div>
                    </div>
                </div>
            }
        />
    );
};

export default TestTypeChip;
