import React, { useState } from 'react';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';

import { useAppDispatch, useAppSelector } from 'src/store';
import { getIsSiteMenuOpen } from 'src/global/state/site-menu/siteMenuSelector';
import classNames from 'classnames';
import { getAppRoutesAvailability } from 'src/routes/state/routesSelector';
import { useLocation, useNavigate } from 'react-router-dom';
import { openSiteMenu } from 'src/global/state/site-menu/siteMenuSlice';
import ChevronButton from 'src/shared/components/chevron-button/ChevronButton';
import styles from '../SiteMenu.module.scss';
import { tableRouteSettings } from 'src/app/tables/Tables';
import useProject from 'src/shared/hooks/useProject';
import { SubscriptionType } from 'src/shared/types';

const TablesMenu = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isOpen = useAppSelector(getIsSiteMenuOpen);
    const appRoutesAvailability = useAppSelector(getAppRoutesAvailability);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { currentProjectSummary } = useProject();
    const isHabitatInsightsProject = currentProjectSummary?.survey?.subscription?.name === SubscriptionType.SURVEY_WITH_HABITAT_INSIGHTS;

    if (!appRoutesAvailability.tables) {
        return null;
    }

    const getMenuItemClassNames = () => {
        return classNames(styles.menuItem, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: pathname.startsWith('/tables'),
        });
    };

    const getMenuChildItemClassNames = (path: string) => {
        return classNames(styles.menuItem, styles.menuItemChild, {
            [styles.menuItemOpen]: isOpen,
            [styles.menuItemActive]: pathname.includes(path),
        });
    };

    const toggleExpanded = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();

        setIsExpanded(!isExpanded);
    };
    const openMenu = () => {
        dispatch(openSiteMenu());
        setIsExpanded(!isExpanded);
    };

    const onMenuClick = (path: string) => {
        navigate('/tables' + path);
    };

    return (
        <>
            <div className={getMenuItemClassNames()} onClick={openMenu}>
                <div className={styles.icon}>
                    <GridOnOutlinedIcon />
                </div>
                <div className={styles.label}>Tables</div>
                {isOpen && (
                    <ChevronButton
                        className={styles.chevron}
                        animate={true}
                        direction={isExpanded ? 'down' : 'right'}
                        onClick={e => toggleExpanded(e)}
                    />
                )}
            </div>
            {isOpen && isExpanded && (
                <>
                    {!isHabitatInsightsProject && (
                        <div
                            className={getMenuChildItemClassNames(tableRouteSettings.sampleMetrics.path)}
                            onClick={() => onMenuClick(tableRouteSettings.sampleMetrics.path)}
                        >
                            <div className={styles.label}>{tableRouteSettings.sampleMetrics.name}</div>
                        </div>
                    )}
                    {isHabitatInsightsProject && (
                        <div
                            className={getMenuChildItemClassNames(tableRouteSettings.habitatInsightsMetrics.path)}
                            onClick={() => onMenuClick(tableRouteSettings.habitatInsightsMetrics.path)}
                        >
                            <div className={styles.label}>{tableRouteSettings.habitatInsightsMetrics.name}</div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default TablesMenu;
