import { isEqual } from 'lodash';
import { type AnalyticsOptions } from 'src/shared/helpers/analyticsHelper';
import config from 'src/config';
import GoogleAnalytics, { TrackEventParams, GtagConfig, defaultGtagConfig } from 'src/services/google-analytics';

const { googleAnalyticsKey } = config;

class AnalyticsTracking {
    private googleAnalytics: typeof GoogleAnalytics | null = null;
    private currentConfig: GtagConfig = { ...defaultGtagConfig };

    public constructor() {
        // don't initialise analytics if the deployment environment is not production
        if (config.deploymentEnv !== 'prod') {
            return;
        }
        GoogleAnalytics.initialize(googleAnalyticsKey);
        this.googleAnalytics = GoogleAnalytics;
    }

    public initialize() {
        // don't initialise analytics if the deployment environment is not production
        if (config.deploymentEnv !== 'prod') {
            return;
        }
        if (!this.googleAnalytics) {
            GoogleAnalytics.initialize(googleAnalyticsKey);
            this.googleAnalytics = GoogleAnalytics;
        }
    }

    // Track a pageview
    public trackPageView(pagePath: string) {
        this.googleAnalytics?.pageview({
            page_path: pagePath,
        });
    }

    // Track an event
    public trackEvent(options: AnalyticsOptions) {
        const eventParams: TrackEventParams = {
            event_action: options.action,
            event_category: options.category,
        };

        if (options.label) {
            eventParams.event_label = options.label;
        }

        if (options.value) {
            eventParams.value = options.value;
        }

        if (options.app) {
            eventParams.app = options.app;
        }

        if (options.habitatAssayType) {
            eventParams.habitatAssayType = options.habitatAssayType;
        }

        if (options.project) {
            eventParams.project = options.project;
        }

        if (options.non_interaction) {
            eventParams.non_interaction = options.non_interaction;
        }

        this.googleAnalytics?.event(eventParams);
    }

    public updateConfig(newConfig: GtagConfig) {
        const updatedConfig = { ...this.currentConfig, ...newConfig };
        const isConfigUpdated = !isEqual(this.currentConfig, updatedConfig);
        if (isConfigUpdated) {
            this.currentConfig = { ...updatedConfig };
            this.googleAnalytics?.updateConfig(googleAnalyticsKey, { ...this.currentConfig, debug_mode: config.deploymentEnv !== 'prod' });
        }
    }
}

const analyticsTracking = new AnalyticsTracking();

export default analyticsTracking;
